<template>
    <base-scheda-generale :model="model" :items="items"></base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import moment from '@/moment';

    export default {
        name: 'SchedaTipologiaGenerale',
        components: {BaseSchedaGenerale},
        props: {model: Object, testo: Object},
        computed: {
            items: function () {
                return [
                    {label: 'Nome', data: this.testo.NOME},
                    {label: 'Descrizione', data: this.testo.DESCRIZIONE},
                    {label: 'Testi Associati', data: this.testiAssociati},
                    {label: 'Eventi Associati', data: this.model.EV_COUNT},
                    {label: 'Visualizzazioni', data: this.model.VISUALIZZAZIONI},
                    {
                        label: 'Disattivazione Manuale',
                        data: this.model.DATA_DISATTIVAZIONE ? moment.toDate(this.model.DATA_DISATTIVAZIONE) : 'MAI'
                    }
                ]
            },
            testiAssociati: function () {
                return this.model.testi.length + ' ( ' + this.model.testi.map(t => t.LNG_SIGLA).join(', ') + ' )';
            }
        }
    }
</script>

<style scoped>

</style>
