<template>
    <base-scheda models-name="tipologie">

        <template #header="{ model }">
            <scheda-tipologia-header :model="model"
                                     v-model="idLinguaFk"
                                     @finish="isLingueLoading = false"
            ></scheda-tipologia-header>
        </template>

        <template #titolo="{ model }">

            <loading-resouces v-if="isLingueLoading"
                              class="pt-4"
                              icon="fa-flag"
                              messaggio="Recupero delle lingue in corso"
            ></loading-resouces>

            <scheda-tipologia-titolo v-else-if="Boolean(idLinguaFk)"
                                     :model="model"
                                     :testo="getTesto(model)"
            ></scheda-tipologia-titolo>

            <no-data-available v-else models-name="lingue" type="lingue"></no-data-available>

        </template>

        <template #generale="{ model }" v-if="Boolean(idLinguaFk)">
            <scheda-tipologia-generale :model="model" :testo="getTesto(model)"></scheda-tipologia-generale>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import SchedaTipologiaHeader from '@/components/secure/tipologie/components/SchedaTipologiaHeader';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import SchedaTipologiaTitolo from '@/components/secure/tipologie/components/SchedaTipologiaTitolo';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import SchedaTipologiaGenerale from '@/components/secure/tipologie/components/SchedaTipologiaGenerale';

    export default {
        name: 'SchedaTipologia',
        components: {
            SchedaTipologiaGenerale,
            SchedaTipologiaTitolo,
            SchedaTipologiaHeader,
            LoadingResouces,
            NoDataAvailable,
            BaseScheda
        },
        data: () => ({
            isLingueLoading: true,
            idLinguaFk: undefined
        }),
        methods: {
            getTesto(tipologia) {

                let testo = tipologia.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = tipologia.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = tipologia.testi[0];
                }

                return testo;

            }
        }
    }
</script>

<style scoped>

</style>
