<template>
    <base-scheda-titolo models-name="tipologie"
                        :model="model"
                        model-ref="la tipologia"
                        :titolo="testo.NOME + ' (' + testo.LNG_SIGLA + ')'"
                        :can-edit="$store.getters.isSystem"
                        :can-delete="$store.getters.isSystem"
                        check-text="Elimina anche tutti gli eventi associati"
    >
        <template #pre-titolo>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on">fa fa-{{ !model.IS_PERCORSO ? 'calendar-alt' : 'route' }}</v-icon>
                </template>
                <span>{{ !model.IS_PERCORSO ? 'EVENTO' : 'PERCORSO' }}</span>
            </v-tooltip>
        </template>
    </base-scheda-titolo>
</template>

<script>
    import BaseSchedaTitolo from '@/components/aaaProject/scheda/BaseSchedaTitolo';

    export default {
        name: 'SchedaTipologiaTitolo',
        components: {BaseSchedaTitolo},
        props: {model: Object, testo: Object}
    }
</script>

<style scoped>

</style>
