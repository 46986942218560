import { render, staticRenderFns } from "./SchedaTipologia.vue?vue&type=template&id=51aac452&scoped=true&"
import script from "./SchedaTipologia.vue?vue&type=script&lang=js&"
export * from "./SchedaTipologia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51aac452",
  null
  
)

export default component.exports